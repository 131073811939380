<div class="main-page bg-white">
  <div class="fixed-top browser-width" *ngIf="isLoaded">
    <div class="fixed-bottom main-header p-1">
      <a href="https://roboost.app/" target="_blank" class="d-flex align-items-center" *ngIf="isEnLanguage()">
        <p class="font-14 bold mb-0 ms-auto me-3">Powered By</p>
        <img src="assets/icon/logo.svg" class="ms-auto" [style.height]="'16px'">
      </a>
      <a href="https://roboost.app/" target="_blank" class="d-flex align-items-center" *ngIf="!isEnLanguage()">
        <img src="assets/icon/logo.svg" class="ms-auto" class="ms-auto" [style.height]="'16px'">
        <p class="font-14 bold mb-0 me-auto ms-3">Powered By</p>
      </a>
    </div>
    <div class="card  b-1 bc-white no-radius" *ngIf="isNavLG()"
      [style.background-color]="mainInfo.CompanyBackgroundColor"
      [ngClass]="mainInfo.CompanyID ==36 ?'px-0 py-2 mb-2':'p-3'">
      <div class="d-flex align-items-center">
        <ng-container *ngIf="mainInfo.CompanyID !=36">
          <!-- <div id="slogan" class="img-40 b-2 bc-white radius-12 me-3"></div> -->
          <!-- <img [src]="mainInfo.CompanyLogo" class="img-40 bg-white  radius-12 me-3"> -->
          <!-- <p class="c-white font-18 bold capitalize">{{mainInfo.CompanyName}}</p> -->
          <div class="d-flex align-items-center ms-auto">
            <!-- <img src="assets/icon/customer-service-2-line.svg" class="img-28  me-2"> -->
            <p class="c-008 font-22 bold">19600</p>
          </div>

        </ng-container>
        <!-- <ng-container *ngIf="mainInfo.CompanyID==36">
          <div class="d-flex align-items-center"><img style="width: 250px;" [src]="mainInfo.CompanyLogo"><img
              src="./assets/img/buffalo-burger-hotline.png" style="width: 150px; margin-inline-start: auto;"></div>
        </ng-container> -->

      </div>
    </div>
  </div>

  <div class="main-content browser-width" [class.bg-e0]="getCurrentPage()=='c'" [class.pt-110]="isNavLG()"
    [class.pt-37]="! isNavLG()" [class.mt-3]="mainInfo.CompanyID==36">
    <router-outlet></router-outlet>
  </div>

</div>