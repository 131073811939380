// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  pageSize:20,
  mapApiKey:"AIzaSyD_dUTxYYuF6Pn5_sbV2MsFv3iDjrQMnso",
    // api:'http://localhost:63429'
  // api:'https://api.tayar.info'
  // api:'http://192.168.1.10:100'
  // api:'https://staging-api.tayar.app'
  //  api:'https://tarshouby-api.roboost.app'
  // api:'http://api.dev.tayar.info'
  // api:'https://buffaloburger-api.roboost.app'
  api:'https://elezaby-api.roboost.app'


}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
